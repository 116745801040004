import React from 'react';
import FlexibleLink from '../../ui/FlexibleLink';
import Headline from '../../ui/Headline';
import Container from '../../ui/Grid/Container';
import RichText from '../../ui/RichText';
import * as styles from './styles.module.scss';

const Text = ({ headline, text, link, downloads }) => {
  return (
    <Container additionalClass={styles.container}>
      <Headline
        level={downloads && !text ? 3 : 2}
        centered={downloads && !text ? true : false}
        additionalClass={downloads && !text ? 'mt-100' : ''}
        color={downloads && !text ? 'Black' : 'Primary'}
      >
        {headline}
      </Headline>
      {text && (
        <div className={styles.textWrapper}>
          <RichText content={text} additionalClass={styles.text} />
        </div>
      )}
      {link && <FlexibleLink link={link} />}
      {downloads && (
        <div className={styles.btnGroup}>
          {downloads.map((item, index) => (
            <FlexibleLink link={item} key={index} additionalClass={styles.btn} />
          ))}
        </div>
      )}
    </Container>
  );
};

export default Text;
